import React from "react";
import { MsalContext } from "@azure/msal-react";
import Mis_solicitudes from "./paginas/Mis_solicitudes";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = { datosCargados: false, empleados: [] };
  }
  static contextType = MsalContext;

  cargarDatos() {
    const msalAccounts = this.context.accounts;
    const correo = msalAccounts[0] && msalAccounts[0].username;
    fetch("API/?login=" + correo)
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        this.setState({ datosCargados: true, empleados: datosRespuesta });
      })
      .catch(console.log);
  }

  componentDidMount() {
    this.cargarDatos();
  }
  render() {
    const { datosCargados, empleados } = this.state;
    if (!datosCargados) {
      return (
        <>
          <div className="m-0 p-0 vh-100  row justify-content-center align-content-center ">
            <div className="card bg-light text-dark       w-auto       ">
              <img
                src="Aliat-Universidades.png"
                className="card-img-top"
                alt="..."
              ></img>
              <div className="card-body">
                <p className="card-text">Iniciado Sesión........</p>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <Mis_solicitudes empleado={empleados[0]} />
        </>
      );
    }
  }
}

export default Login;
