import React, { Component } from "react";
import InfoUsuario from "../../subcomponents/InfoUsuario";
import Solicitud from "../Solicitud";
import { MsalContext } from "@azure/msal-react";
import SolicitudesEnProceso from "../../subcomponents/SolicitudesEnProceso";
import { Button, Modal } from "react-bootstrap";
import Resumen from "../../subcomponents/Resumen";

class Mis_solicitudes extends Component {
  constructor(props) {
    super(props);
    this.state = { datosCargados: false, empleados: [], sModal: false };
  }
  static contextType = MsalContext;
  showModal = () => {
    const { sModal } = this.state;

    this.setState({ sModal: !sModal });
  };
  cargarDatos() {
    const msalAccounts = this.context.accounts;
    const correo = msalAccounts[0] && msalAccounts[0].username;
    fetch("API/?login=" + correo)
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        this.setState({ datosCargados: true, empleados: datosRespuesta });
      })
      .catch(console.log);
  }

  componentDidMount() {
    this.cargarDatos();
  }

  render() {
    const { datosCargados, empleados } = this.state;

    if (datosCargados) {
      console.log(empleados[0]);
      return (
        <>
          <h1 className="text-center p-1 ">Mis Solicitudes</h1>

          <div className="row justify-content-center ">
            <div className="col-12 col-sm-12 col-md-8 col-lg-8 ">
              <InfoUsuario empleado={empleados[0]} />
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 col-12">
              <div className="row col-lg-12 col-md-12 col-sm-12 col-12   justify-content-center  m-0 p-4">
                <Solicitud empleado={empleados[0]} />
              </div>
            </div>
          </div>

          <Resumen empleado={empleados[0]} />

          <div className="table-responsive col-lg-12 col-md-12 col-sm-12 cil-12 shadow p-2 mt-2 justify-content-center  ">
            <SolicitudesEnProceso empleado={empleados[0]} />
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Cargando...</span>
            </div>
          </div>
        </>
      );
    }
  }
}

export default Mis_solicitudes;
