import React, { Component } from "react";
import Barra from "./components/navegacion/Barra";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Mis_solicitudes from "./components/paginas/Mis_solicitudes";
import Contacto_ch from "./components/paginas/Contacto_ch";
import Mi_equipo from "./components/paginas/Mi_equipo.jsx";
import Saldo_liquidaciones from "./components/paginas/Mi_equipo.jsx";
import {
  MsalContext,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { PageLayout } from "./components/PageLayout";
import Login from "./components/login";
import Formato from "./subcomponents/Formato";

class App extends Component {
  static contextType = MsalContext;
  constructor(props) {
    super(props);
    this.state = { estado: false, empleado: [] };
  }

  cargarDatos() {
    const msalAccounts = this.context.accounts;
    const correo = msalAccounts[0] && msalAccounts[0].username;
    fetch("API/?login=" + correo)
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        this.setState({ estado: true, empleado: datosRespuesta });
      })
      .catch(console.log);
  }

  componentDidMount() {
    this.cargarDatos();
  }

  render() {
    const { empleado } = this.state;

    return (
      <>
        <Router>
          <Barra />
          <div className="align-conten-center  p-0 m-lg-5 m-md-5 m-sm-5 m-3 mt-5">
            <UnauthenticatedTemplate>
              <div className="m-0 p-0 vh-100  row justify-content-center align-content-center ">
                <div className="card bg-light text-dark       w-auto       ">
                  <img
                    src="Aliat-Universidades.png"
                    className="card-img-top"
                    alt="..."
                  ></img>{" "}
                  <div className="card-body">
                    <p className="card-text"> No has iniciado sesión. </p>{" "}
                    <UnauthenticatedTemplate>
                      <ul className="navbar-nav  ms-auto">
                        <li className="nav-item ">
                          <PageLayout />
                        </li>{" "}
                      </ul>{" "}
                    </UnauthenticatedTemplate>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </UnauthenticatedTemplate>{" "}
            <AuthenticatedTemplate>
              <div className="row pt-4 mt-4">
                <Routes>
                  <Route
                    path="/mis_solicitudes/"
                    element={<Mis_solicitudes empleado={empleado[0]} />}
                  />
                  <Route path="/mi_equipo/" element={<Mi_equipo />} />{" "}
                  <Route path="/" element={<Login />} />{" "}
                  <Route
                    path="/saldo_liquidaciones"
                    element={<Saldo_liquidaciones />}
                  />{" "}
                  <Route path="/formato" element={<Formato />} />{" "}
                </Routes>{" "}
              </div>{" "}
            </AuthenticatedTemplate>{" "}
          </div>{" "}
        </Router>{" "}
      </>
    );
  }
}
export default App;
