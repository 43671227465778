import React, { Component } from "react";
import "./formato.css";
import moment from "moment";

class Formato extends Component {
  constructor(props) {
    super(props);
    console.log(props.datos.regresa);

    this.state = {
      emplid: props.datos.emplid,
      nombres: props.datos.nombres,
      apellido_paterno: props.datos.apellido_paterno,
      apellido_materno: props.datos.apellido_materno,
      plantel: props.datos.plantel,
      fecha_solicitud: props.datos.fecha_solicitud,
      campus: props.datos.campus,
      direccion: props.datos.direccion,
      fecha_inicial: props.datos.f_i,
      fecha_final: props.datos.f_f,
      dias_disponibles: props.dias_disponibles,
      dias_solicitados: props.datos.solicitados,
      dias_restantes: props.datos.dias_disponibles - props.datos.solicitados,
      regresa: props.datos.regresa,
    };
  }

  render() {
    const {
      emplid,
      nombres,
      apellido_paterno,
      apellido_materno,
      plantel,
      dias_disponibles,
      dias_solicitados,
      dias_restantes,
      direccion,
      campus,
      fecha_solicitud,
      fecha_inicial,
      fecha_final,
      regresa,
    } = this.state;

    return (
      <>
        <div className="container row text-left">
          <div className="col-12">
            <img src="Logo-Aliat.webp" width="50%" alt="" align="left"></img>
          </div>
          <div className="col-12 text-center">
            <h5> Solicitud de vacaciones</h5>

            <h5>Dirección Institusional de Capital Humano</h5>
            <h5>FO-CH-11</h5>
          </div>
          <br />

          <h4 className="text-left">I. DATOS DEL SOLICITANTE</h4>
          <br />
          <div className="row p-0 m-0">
            <div className="col-3 border border-secondary  titulo">
              ID de Empleado
            </div>
            <div className="col-9  row border border-secondary p-0 m-0  titulo">
              <div className="col-4 "> Apellido Paterno</div>
              <div className="col-4 "> Apellido Materno</div>
              <div className="col-4 "> Nombre(s)</div>
            </div>
            <div className="col-3 border border-secondary"> {emplid}</div>
            <div className="col-9  row border border-secondary p-0 m-0">
              <div className="col-4">{apellido_paterno}</div>
              <div className="col-4"> {apellido_materno}</div>
              <div className="col-4"> {nombres}</div>
            </div>

            <div className="col-4 border border-secondary  titulo">Campus</div>
            <div className="col-5  border border-secondary  titulo">
              Dirección a la que pertenece
            </div>
            <div className="col-3  border border-secondary  titulo">
              Fecha de solicitud
            </div>
            <div className="col-4 border border-secondary">{campus}</div>
            <div className="col-5  border border-secondary">{direccion}</div>
            <div className="col-3  border border-secondary">
              {moment(fecha_solicitud).format("DD/MM/yyyy")}
            </div>
          </div>
          <br />
          <br />
          <h3 className="text-left">I. DATOS DEL PERIODO VACACIONAL</h3>
          <br />
          <div className="row p-0 m-0">
            <div className="col-7  border border-secondary  titulo">
              Los días comprenden las siguientes fechas:
            </div>
            <div className="col-5  border border-secondary  titulo">
              Reanudando labores el día:
            </div>
            <div className="row col-7  border border-secondary  p-0 m-0">
              <div className="col-5 p-0 m-0">
                {moment(fecha_inicial).format("DD/MM/yyyy")}
              </div>
              <div className="col-2 p-0 m-0">AL</div>
              <div className="col-5 p-0 m-0">
                {moment(fecha_final).format("DD/MM/yyyy")}
              </div>
            </div>
            <div className="col-5  border border-secondary">
              {moment(regresa).format("DD/MM/yyyy")}
            </div>
          </div>

          <div className="col-12 row p-0 m-0">
            <div className="col-1">
              <input type="checkbox" className="form-check-input p-2" />
            </div>
            <div className="col-11">
              Acepto términos y condiciones:
              <button
                className=" btn-link bg-transparent border-0"
                type="button"
              >
                Términos de Uso del sitio
              </button>
              .Conozco los términos y alcance de la politica PO-CH-11 "Politica
              de Vacaciones", así como la Ley Federal de Trabajo y estoy de
              acuerdo y conforme en que se apliquen dichas condiciones.
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Formato;
