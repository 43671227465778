import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";

const Resumen = ({ empleado }) => {
  const [cargado, setCargado] = useState(false);
  const [data, setData] = useState([]);
  const [dias_disponibles, setDias_disponibles] = useState(0);

  useEffect(() => {
    if (empleado.emplid > 0) {
      fetch("http://localhost/API/?solicitudes=" + empleado.emplid)
        .then((respuesta) => respuesta.json())
        .then((datosRespuesta) => {
          setCargado(true);
          setData(datosRespuesta);
        })
        .catch(console.log);
    }
  }, [empleado]);

  useEffect(() => {
    if (empleado.emplid > 0) {
      fetch("http://localhost/API/?periodos=" + empleado.emplid)
        .then((respuesta) => respuesta.json())
        .then((datosRespuesta) => {
          setDias_disponibles(datosRespuesta[0].disponibles);
        })
        .catch(console.log);
    }
  }, [empleado]);

  console.log(dias_disponibles);
  console.log(data);

  return (
    <>
      <div className="table-responsive col-lg-10 col-md-10 col-sm-12 cil-12 shadow p-2 mt-2 justify-content-center rounded  ">
        <h4 className="text-center p-1">
          Estimado Colaborador, usted tiene {dias_disponibles} días pendientes
          de vacaciones sin programar
        </h4>

        <Table bordered responsive hover>
          <thead>
            <tr>
              <th>Periodo</th>
              <th>Estado</th>
              <th>Formato</th>
            </tr>
          </thead>
          <tbody>
            {data.map(({ fecha_inicial, fecha_final, status, folio }) => {
              if (status === "Autorizado") {
                return (
                  <tr key={folio}>
                    <td>
                      De {fecha_inicial} a {fecha_final}
                    </td>
                    <td>{status}</td>
                    <td>{folio}</td>
                  </tr>
                );
              }
            })}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default Resumen;
