import React, { useState, useEffect } from "react";
import { Pagination, Table } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import moment from "moment";
import toast, { Toaster } from "react-hot-toast";
import {
  Modal,
  Button,
  Row,
  Col,
  FormControl,
  InputGroup,
  FloatingLabel,
} from "react-bootstrap";
import Calendario from "../components/Calendario";

const SolicitudesEnProceso = ({ empleado }) => {
  const [cargado, setCargado] = useState(false);
  const [data, setData] = useState([]);
  const [pdata, setPdata] = useState([]);
  const [pagina, setPagina] = useState(1);

  useEffect(() => {
    if (empleado.emplid > 0) {
      fetch("API/?solicitudes=" + empleado.emplid)
        .then((respuesta) => respuesta.json())
        .then((datosRespuesta) => {
          setCargado(true);
          setData(datosRespuesta);
        })
        .catch(console.log);
    }
  }, [cargado]);

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [sbtn, setSbtn] = useState(true);
  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);
  const [folio, setFolio] = useState(0);
  const [status, setStatus] = useState(0);
  const [comentario, setComentario] = useState(0);
  const [fecha_inicial, setFecha_inicial] = useState(0);
  const [fecha_final, setFecha_final] = useState(0);
  const [fecha_reanuda, setFecha_reanuda] = useState(0);
  const [fecha_solicitud, setFecha_solicitud] = useState(0);
  const [dias_solicitados, setDias_solicitados] = useState(0);
  const [periodo, setPeriodo] = useState(0);
  const [nombres, setNombres] = useState(0);
  const [apellido_paterno, setApellido_paterno] = useState(0);
  const [apellido_materno, setApellido_materno] = useState(0);
  const [motivo, setMotivo] = useState("");

  const Confirmar = () => {
    setShow(false);
    setShow1(true);
  };

  const Cancelar = ({ folio, motivo }) => {
    if (!motivo) {
      toast.error("Introduce Motivo de Cancelación");
      return;
    }
    fetch("API/?cancelar=" + folio + "&motivo=" + motivo)
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success) {
          toast.error("Solicitud cancelada con Exito");
          setShow1(false);
        } else {
          toast.error("Error al Cancelar");
          setShow1(false);
        }

        setCargado(false);
      })
      .catch(console.log);
  };

  const Paginar = (datos, pagina) => {
    return datos.slice((pagina - 1) * 4, pagina * 4);
  };

  const Buscar = (datos, busqueda) => {
    let b = datos.filter((elemento) => elemento.includes(busqueda));
    console.log(b); // ["foo","fool","cool"]
  };

  const Paginamax = (datos) => {
    let pmax = datos.length / 4;
    let resto = datos.length % 4;

    if (resto > 0) {
      pmax = pmax + 1;
    }

    return pmax;
  };
  const handleShow = ({
    folio,
    status,
    comentario,
    fecha_inicial,
    fecha_final,
    fecha_reanuda,
    fecha_solicitud,
    dias_solicitados,
    periodo,
    nombres,
    apellido_paterno,
    apellido_materno,
    motivo,
  }) => {
    setFolio(folio);
    setStatus(status);
    setComentario(comentario);
    setFecha_inicial(fecha_inicial);
    setFecha_final(fecha_final);
    setFecha_reanuda(fecha_reanuda);
    setFecha_solicitud(fecha_solicitud);
    setDias_solicitados(dias_solicitados);
    setPeriodo(periodo);
    setNombres(nombres);
    setApellido_materno(apellido_materno);
    setApellido_paterno(apellido_paterno);
    setMotivo(motivo);

    if (status === "Rechazado" || status === "Cancelada") {
      setSbtn(true);
    } else {
      setSbtn(false);
    }

    setShow(true);
  };

  if (cargado) {
    return (
      <>
        <Table bordered hover>
          <thead>
            <tr>
              <th className="col-1">Folio</th>
              <th className="col-1">Estatus</th>
              <th className="col-10">Comentario</th>
            </tr>
          </thead>
          <tbody>
            {Paginar(data, pagina).map(
              ({
                folio,
                status,
                comentario,
                fecha_inicial,
                fecha_final,
                fecha_reanuda,
                fecha_solicitud,
                dias_solicitados,
                periodo,
                nombres,
                apellido_paterno,
                apellido_materno,

                motivo,
              }) => {
                return (
                  <tr
                    key={folio}
                    onClick={() => {
                      handleShow({
                        folio,
                        status,
                        comentario,
                        fecha_inicial,
                        fecha_final,
                        fecha_reanuda,
                        fecha_solicitud,
                        dias_solicitados,
                        periodo,
                        apellido_paterno,
                        apellido_materno,
                        nombres,
                        motivo,
                      });
                    }}
                  >
                    <td>{folio}</td>
                    <td>{status}</td>
                    <td>{comentario}</td>
                  </tr>
                );
              }
            )}
          </tbody>
        </Table>

        <Pagination>
          <Pagination.First onClick={() => setPagina(1)} />
          <Pagination.Prev
            onClick={() => setPagina(pagina > 1 ? pagina - 1 : 1)}
          />

          <Pagination.Item active>{pagina}</Pagination.Item>

          <Pagination.Next
            onClick={() =>
              setPagina(pagina < Paginamax(data) ? pagina + 1 : Paginamax(data))
            }
          />
          <Pagination.Last
            onClick={() => {
              setPagina(Paginamax(data));
            }}
          />
        </Pagination>

        <Modal
          className="rounded"
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          size="md"
        >
          <Modal.Header className="p-0 m-0">
            <Modal.Title className="text-center w-100 bg-primary text-white ">
              <Row>
                <Col className="col-10">Información de Solicitud</Col>
                <Col className="col-2">
                  <Button
                    variant="transparent"
                    onClick={handleClose}
                    className="float-right"
                  >
                    <i className="fa-solid fa-xmark"></i>
                  </Button>
                </Col>
              </Row>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0 m-0">
            <Row className="p-0 m-0">
              <Col className="p-1 m-0 col-4 ">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Folio"
                  className="mb-3"
                >
                  <FormControl aria-label="Folio" value={folio} readOnly />
                </FloatingLabel>
              </Col>
              <Col className="p-1 m-0 col-4">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Estatus"
                  className="m-0 mb-3"
                >
                  <FormControl
                    aria-label="Estatus"
                    value={status}
                    name="status"
                    readOnly
                  />
                </FloatingLabel>
              </Col>
              <Col className="p-1 m-0 col-4">
                <FloatingLabel
                  controlId="floatingInput"
                  label="F. de Solictud"
                  className="mb-3 "
                >
                  <FormControl
                    aria-label="Fecha de Solictud"
                    value={moment(fecha_solicitud).format("DD/MM/yy")}
                    type="textl"
                    readOnly
                  />
                </FloatingLabel>
              </Col>
              <Col className="p-1 m-0 col-10">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Nombre"
                  className="mb-3"
                >
                  <FormControl
                    aria-label="Nombre"
                    value={
                      nombres + " " + apellido_paterno + " " + apellido_materno
                    }
                    readOnly
                  />
                </FloatingLabel>
              </Col>
              <Col className="p-1 m-0 col-2 col-sm-2 col-md-2 col-lg-2">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Días"
                  className="mb-3"
                >
                  <FormControl
                    className="text-center"
                    aria-label="Días"
                    value={dias_solicitados}
                    type="number"
                    readOnly
                  />
                </FloatingLabel>
              </Col>

              <Col className="p-1 m-0 col-4">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Fecha Inicial"
                  className="mb-3"
                >
                  <FormControl
                    aria-label="Fecha Inicial"
                    value={moment(fecha_inicial).format("DD/MM/yy")}
                    type="text"
                    readOnly
                  />
                </FloatingLabel>
              </Col>
              <Col className="p-1 m-0 col-4 ">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Fecha Final"
                  className="mb-3"
                >
                  <FormControl
                    aria-label="Fecha Final"
                    value={moment(fecha_final).format("DD/MM/yy")}
                    type="text"
                    readOnly
                  />
                </FloatingLabel>
              </Col>
              <Col className="p-1 m-0 col-4 ">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Reanuda"
                  className="mb-3"
                >
                  <FormControl
                    aria-label="Fecha Reanuda"
                    value={moment(fecha_reanuda).format("DD/MM/yy")}
                    type="text"
                    readOnly
                  />
                </FloatingLabel>
              </Col>

              <Col className="p-1 m-0 col-12 col-sm-12 col-md-12 col-lg-12">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Comentario"
                  className="mb-3"
                >
                  <FormControl
                    as="textarea"
                    placeholder="Introduce algun comentario (Opcional)"
                    style={{ height: "100px" }}
                    value={comentario}
                    type="text"
                    readOnly
                  />
                </FloatingLabel>
              </Col>
              <Col className="p-1 m-0 col-12 col-sm-12 col-md-12 col-lg-12">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Motivo de Rechazo o Cancelación"
                  className="mb-3"
                  hidden={!motivo && true}
                >
                  <FormControl
                    as="textarea"
                    placeholder="motivoes"
                    style={{ height: "100px" }}
                    value={motivo}
                    type="text"
                    readOnly
                  />
                </FloatingLabel>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="m-0 p-0">
            <Button
              variant="danger"
              hidden={sbtn}
              folio={folio}
              onClick={() => Confirmar()}
            >
              <i className="fa-solid fa-trash-can"></i> Cancelar Solicitud
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={show1}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          size="sm"
        >
          <Modal.Header className="p-0 m-0">
            <Modal.Title className="text-center w-100 bg-dark text-white">
              Confirmación
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col className="p-1 m-0 col-12 col-sm-12 col-md-12 col-lg-12">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Motivo de la Cancelación"
                  className="mb-3"
                >
                  <FormControl
                    as="textarea"
                    placeholder="motivoes"
                    style={{ height: "100px" }}
                    value={motivo}
                    onChange={(e) => setMotivo(e.target.value)}
                    type="text"
                  />
                </FloatingLabel>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="m-0 p-1">
            <div className="row m-0 p-0 w-100">
              <div className="col-6 m-0 p-0 w-50">
                <Button
                  variant="success"
                  size="lg"
                  className="w-100"
                  onClick={() => Cancelar({ folio, motivo })}
                >
                  Confirmar
                </Button>
              </div>

              <div className="col-6 m-0 p-0 w-50">
                {" "}
                <Button
                  variant="secondary"
                  size="lg"
                  className=" w-100 btn-block"
                  onClick={handleClose1}
                >
                  Cerrar
                </Button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  } else {
    return (
      <>
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Cargando...</span>
          </div>
        </div>
      </>
    );
  }
};

export default SolicitudesEnProceso;
