import React from "react";

const InfoUsuario = ({ empleado }) => {
  return (
    <>
      <div className="row col-12 shadow rounded m-0 p-3  ">
        <div className="col-1 h1 p-1 m-0">
          <i className="fa-solid fa-user fa-2xl shadow"></i>
        </div>
        <div className="row col-11 p-1 m-0">
          <div className="col-lg-6 col-md-6  col-sm-12 col-12 h5 ">
            Nombre:{" "}
            {empleado.nombres +
              " " +
              empleado.apellido_paterno +
              " " +
              empleado.apellido_materno}
          </div>
          <div className="col-lg-6 col-md-6  col-sm-12 col-12 h5 ">
            Ingreso: {empleado.fecha_de_ingreso}
          </div>
          <div className="col-lg-6 col-md-6  col-sm-12 col-12 h5">
            Id: {empleado.emplid}
          </div>
          <div className="col-lg-6 col-md-6  col-sm-12 col-12 h5 ">
            Puesto: {empleado.puesto}
          </div>
        </div>
      </div>
    </>
  );
};

export default InfoUsuario;
