import React, { Component } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import Formato from "../subcomponents/Formato";
import toast, { Toaster } from "react-hot-toast";

import {
  Modal,
  Button,
  Row,
  Col,
  FloatingLabel,
  FormControl,
  Form,
} from "react-bootstrap";
import { differenceInCalendarDays } from "date-fns";

class Solicitud extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nombres: props.empleado.nombres,
      apellido_paterno: props.empleado.apellido_paterno,
      apellido_materno: props.empleado.apellido_materno,
      emplid: props.empleado.emplid,
      plantel: props.empleado.plantel,
      campus: props.empleado.campus,
      direccion: props.empleado.direccion,
      fecha_solicitud: moment().format("yyyy-MM-DD"),
      fecha: moment()["_d"],
      dateState: [],
      labora: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      f_i: moment().format("yyyy-MM-DD"),
      f_f: moment().format("yyyy-MM-DD"),
      solicitados: 0,
      checkedUno: true,
      checkedDos: true,
      checkedTres: true,
      dias_disponibles: 0,
      sFormato: false,
      sMensage: "",
      sModal: false,
      sAviso: false,
      periodo: 0,
      sPeriodo: 0,
      sRango: "",
      disabledDates: [],
      regresa: moment()["_d"],
    };
  }
  showModal = () => {
    const { sModal } = this.state;

    this.setState({ sModal: !sModal });
  };
  showAviso = () => {
    const { sAviso } = this.state;

    this.setState({ sAviso: !sAviso });
  };
  cargarDatos() {
    const { emplid } = this.state;
    fetch("API/?periodos=" + emplid)
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        this.setState({ dias_disponibles: datosRespuesta[0].disponibles });
      })
      .catch(console.log);
  }

  cargarDias() {
    fetch("API/?fe=" + moment().format("yyyy"))
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        var arreglo = [];
        datosRespuesta.forEach((element) => {
          arreglo.push(element["fecha"]);
        });

        this.setState({ disabledDates: arreglo });
      })
      .catch(console.log);
  }

  componentDidMount() {
    this.cargarDatos();
    this.cargarDias();
  }

  showFormato = () => {
    const { sFormato, dias_disponibles, solicitados } = this.state;

    if (
      solicitados <= dias_disponibles &&
      solicitados > 0 &&
      dias_disponibles > 0
    ) {
      this.setState({ sFormato: !sFormato });
    } else {
      toast.error("Selecciona Rango de Fechas");
      this.setState({
        f_i: moment().format("yyyy-MM-DD"),
        f_f: moment().format("yyyy-MM-DD"),
        sRango: "",
        solicitados: 0,
      });
    }
  };

  cambiocheck = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.checked;
    this.setState({ state });

    const { checkedUno, checkedDos, checkedTres, f_i, f_f, disabledDates } =
      this.state;
    var labora = [];

    if (checkedUno) {
      labora.push("Mon");
      labora.push("Tue");
      labora.push("Wed");
      labora.push("Thu");
      labora.push("Fri");
    }
    if (checkedDos) {
      labora.push("Sat");
    }
    if (checkedTres) {
      labora.push("Sun");
    }

    var dias = [];

    var lafecha = moment(f_i).format("yyyy-MM-DD");
    do {
      var eldia = moment(lafecha).format("ddd");

      if (
        labora.find((element) => element === eldia) &&
        !disabledDates.find(
          (element) => element === moment(lafecha).format("yyyy-MM-DD")
        )
      )
        dias.push(moment(lafecha).format("yyyy-MM-DD"));

      lafecha = moment(lafecha).add(1, "d");
    } while (
      moment(lafecha).format("yyyy-MM-DD") <= moment(f_f).format("yyyy-MM-DD")
    );

    var regs = moment(f_f).format("yyyy-MM-DD");
    var respuesta = true;
    do {
      regs = moment(regs).add(1, "d");
      var dregs = moment(regs).format("ddd");

      if (
        labora.find((element) => element === dregs) &&
        !disabledDates.find(
          (element) => element === moment(regs).format("yyyy-MM-DD")
        )
      ) {
        this.setState({
          regresa: moment(regs).format("yyyy-MM-DD"),
        });
        respuesta = false;
      }
    } while (respuesta);

    const { dias_disponibles } = this.state;
    if (dias.length <= dias_disponibles) {
      this.setState({
        solicitados: dias.length,
      });
    } else {
      toast.error("Los días seleccionados son mas que los disponibles");
      this.setState({
        f_i: moment().format("yyyy-MM-DD"),
        f_f: moment().format("yyyy-MM-DD"),
        sRango: "",
        solicitados: 0,
      });
    }

    this.setState({ labora: labora });
  };
  cambio = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState({ state });
  };

  rango = (e) => {
    const { dias_disponibles, disabledDates } = this.state;

    if (e.length === 2) {
      this.setState({
        sRango: e,
        f_i: moment(e[0]).format("yyyy-MM-DD"),
        f_f: moment(e[1]).format("yyyy-MM-DD"),
      });
    }
    const { labora } = this.state;

    var dias = [];

    var lafecha = moment(e[0]).format("yyyy-MM-DD");
    do {
      var eldia = moment(lafecha).format("ddd");

      if (
        labora.find((element) => element === eldia) &&
        !disabledDates.find(
          (element) => element === moment(lafecha).format("yyyy-MM-DD")
        )
      )
        dias.push(moment(lafecha).format("yyyy-MM-DD"));

      lafecha = moment(lafecha).add(1, "d");
    } while (
      moment(lafecha).format("yyyy-MM-DD") <= moment(e[1]).format("yyyy-MM-DD")
    );

    var regs = moment(e[1]).format("yyyy-MM-DD");
    var respuesta = true;
    do {
      regs = moment(regs).add(1, "d");
      var dregs = moment(regs).format("ddd");

      if (
        labora.find((element) => element === dregs) &&
        !disabledDates.find(
          (element) => element === moment(regs).format("yyyy-MM-DD")
        )
      ) {
        this.setState({
          regresa: moment(regs).format("yyyy-MM-DD"),
        });
        respuesta = false;
      }
    } while (respuesta);

    if (dias.length <= dias_disponibles) {
      this.setState({
        solicitados: dias.length,
      });
    } else {
      toast.error("Los días seleccionados son mas que los disponibles");
      this.setState({
        f_i: moment().format("yyyy-MM-DD"),
        f_f: moment().format("yyyy-MM-DD"),
        sRango: "",
        solicitados: 0,
      });
    }
  };

  getDate() {
    var date = { currentTime: new Date().toLocaleString() };

    this.setState({
      fecha: date,
    });
  }

  render() {
    const {
      nombres,
      apellido_paterno,
      apellido_materno,
      emplid,
      fecha,
      fecha_solicitud,
      direccion,
      f_i,
      f_f,
      solicitados,
      checkedUno,
      checkedDos,
      checkedTres,
      dias_disponibles,
      sFormato,
      sModal,
      sAviso,
      sMensage,
      sRango,
      sPeriodo,
      disabledDates,
      regresa,
    } = this.state;

    function isSameDay(a, b) {
      return differenceInCalendarDays(a, b) === 0;
    }

    function tileDisabled({ date, view }) {
      // Disable tiles in month view only

      if (view === "month") {
        // Check if a date React-Calendar wants to check is on the list of disabled dates
        return disabledDates.find((dDate) => isSameDay(dDate, date));
      }
    }

    return (
      <>
        <Toaster
          containerStyle={{
            position: "fixed",
            top: "40%",
          }}
          toastOptions={{
            success: {
              style: {
                background: "green",
              },
            },
            error: {
              style: {
                background: "red",

                color: "white",
              },
            },
          }}
        />
        <div className="col-lg-12 ">
          <button
            type="button"
            className="btn btn-block btn-primary  btn-lg w-100"
            onClick={this.showModal}
          >
            + Solicitar Vacaciones
          </button>
        </div>

        <Modal
          centered
          show={sModal}
          onHide={this.showModal}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header className="text-center w-100 bg-dark text-white p-0 m-0 ">
            <Modal.Title className="text-center w-100 bg-primary text-white p-0 m-0 ">
              Solicitud de vacaciones
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col className="col-12 p-0 m-0">
                <Calendar
                  className="w-100"
                  locale="es-ES"
                  minDate={fecha}
                  selectRange={true}
                  view="month"
                  onChange={this.rango}
                  value={sRango}
                  tileDisabled={tileDisabled}
                />
              </Col>

              <Col className="col-4 p-1 m-0">
                <FloatingLabel label="Fecha Inicial" className="mb-3">
                  <FormControl
                    id="f_i"
                    name="f_i"
                    value={moment(f_i).format("DD/MMM/yy")}
                    type="text"
                    readOnly
                  />
                </FloatingLabel>
              </Col>
              <Col className="col-4 p-1 m-0">
                <FloatingLabel label="Fecha Final" className="mb-3">
                  <FormControl
                    id="f_f"
                    name="f_f"
                    value={moment(f_f).format("DD/MMM/yy")}
                    type="text"
                    readOnly
                  />
                </FloatingLabel>
              </Col>
              <Col className="col-4 p-1 m-0">
                <FloatingLabel label="Fecha Regreso" className="mb-3">
                  <FormControl
                    id="regresa"
                    name="regresa"
                    value={moment(regresa).format("DD/MMM/yy")}
                    type="text"
                    readOnly
                  />
                </FloatingLabel>
              </Col>
              <Col className="col-3 p-1 m-0">
                <FloatingLabel label="Disponibles" className="mb-3">
                  <FormControl
                    className="text-center text-dark"
                    id="dias_disponibles"
                    name="dias_disponibles"
                    value={dias_disponibles}
                    readOnly
                    type="number"
                  />
                </FloatingLabel>
              </Col>
              <Col className="col-3 p-1 m-0">
                <FloatingLabel label="Solicitados" className="mb-3">
                  <FormControl
                    className="text-center text-dark"
                    name="solicitados"
                    id="solicitados"
                    value={solicitados}
                    readOnly
                    type="number"
                  />
                </FloatingLabel>
              </Col>
              <Col className="col-6  p-0 m-0 ">
                <div className="row col-12 align-content-center justify-content-center p-4 pt-0 pb-0">
                  <label className="font-weight-bold text-center w-100">
                    ¿Qué días labora?
                  </label>
                  <div className="col-4 p-0 m-0">
                    <Form.Check
                      type="checkbox"
                      id="checkedUno"
                      name="checkedUno"
                      label="L-V"
                      checked={checkedUno}
                      onChange={this.cambiocheck}
                    />
                  </div>
                  <div className="col-4  p-0 m-0">
                    <Form.Check
                      type="checkbox"
                      id="checkedDos"
                      name="checkedDos"
                      label="Sab"
                      checked={checkedDos}
                      onChange={this.cambiocheck}
                    />
                  </div>
                  <div className="col-4 p-0 m-0">
                    <Form.Check
                      type="checkbox"
                      id="checkedTres"
                      name="checkedTres"
                      label="Dom"
                      checked={checkedTres}
                      onChange={this.cambiocheck}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="p-0 m-0">
            <Button variant="secondary" onClick={this.showModal}>
              Cerrar
            </Button>
            <Button variant="primary" onClick={this.showFormato}>
              Generar
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal centered show={sFormato} onHide={this.showFormato} size="lg">
          <Modal.Header className="text-center w-100 bg-dark text-white p-0 m-0 ">
            <Modal.Title className="text-center w-100 bg-dark text-white ">
              Formato
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formato datos={this.state} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showFormato}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          centered
          show={sAviso}
          onHide={this.showAviso}
          size="sm"
          animation={true}
          slide
        >
          <Modal.Header className="p-0 m-0 ">
            <Modal.Title className="text-center w-100 bg-danger text-white ">
              Aviso
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <b>{sMensage}</b>
          </Modal.Body>
          <Modal.Footer className="p-0 m-0 ">
            <Button
              className="btn-block w-100"
              variant="danger"
              onClick={this.showAviso}
              size="sm"
            >
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default Solicitud;
