// import React from "react";
// import { useMsal } from "@azure/msal-react";
// import { loginRequest } from "../authConfig";
// import Button from "react-bootstrap/Button";

// function handleLogin(instance) {
//     instance.loginPopup(loginRequest).catch(e => {
//         console.error(e);
//     });
// }

// /**
//   Renders a button which, when selected, will open a popup for login

// export const SignInButton = () => {
//     const { instance } = useMsal();

//     return (
//         <Button variant="secondary" className="btn btn-block" onClick={() => handleLogin(instance)}>Entrar</Button>
//     );
// }

import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";


function handleLogin(instance) {
    instance.loginRedirect(loginRequest).catch(e => {
        console.error(e);
    });
}

/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
export const SignInButton = () => {
    const { instance } = useMsal();

    return (

<button className="btn btn-primary btn-block w-100  " type="button" onClick={() => handleLogin(instance)}>Entrar</button>

    );
}


