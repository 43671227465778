import React from "react";
import { Link } from "react-router-dom";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { SignOutButton } from "../SignOutButton";
const Barra = () => {
  return (
    <>
      <nav className="navbar navbar-expand-sm  fixed-top       navbar-light bg-light ">
        <div className="navbar-brand ml-2">
          <img src="logo.png" width="40" alt=""></img>Aliat Universidades
        </div>

        <button
          className="navbar-toggler text-dark"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="fa-solid fa-bars"></i>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <AuthenticatedTemplate>
            <ul className="navbar-nav mr-auto">
              <li className="btn bg-transparent ">
                <Link className="nav-link " to="/mis_solicitudes">
                  Mis Solicitudes
                </Link>
              </li>
              <li className="btn bg-transparent ">
                <Link className="nav-link" to="/mi_equipo">
                  Mi equipo
                </Link>
              </li>
              <li className="btn bg-transparent ">
                <Link className="nav-link" to="/contacto_ch">
                  Contacto CH
                </Link>
              </li>
              <li className="btn bg-transparent ">
                <Link className="nav-link" to="/saldo_liquidaciones">
                  Saldo Liquidaciones
                </Link>
              </li>

              <li className="btn bg-transparent">
                <SignOutButton />
              </li>

              <li className="btn bg-transparent ">
                <Link className="nav-link" to="/formato">
                  Formato
                </Link>
              </li>
            </ul>
          </AuthenticatedTemplate>
        </div>
      </nav>
    </>
  );
};

export default Barra;
